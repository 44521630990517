import Vue from 'vue';
import App from './App.vue';
import { createProvider } from './vue-apollo';

Vue.config.productionTip = false;

new Vue({
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount(process.env.NODE_ENV === 'production' ? '#olymp-app' : '#app');
