





















import { Component, Vue } from 'vue-property-decorator';
import OlympSection from './components/OlympSection.vue';
import 'simplebar';
import 'simplebar/dist/simplebar.css';

@Component({
  components: { OlympSection },
})
export default class App extends Vue {
  schoolSections = [
    { title: 'Состязания высшей школы экономики', name: 'school_hse' },
    { title: 'Состязания при поддержке высшей школы экономики', name: 'school_hse_support' },
  ];

  studentSections = [
    { title: 'Состязания высшей школы экономики', name: 'student_hse' },
    { title: 'Состязания при поддержке высшей школы экономики', name: 'student_hse_support' },
  ];

  get allSections() {
    return [
      ...this.schoolSections,
      ...this.studentSections,
    ];
  }

  scrollToAnchor() {
    // формально hash может поменяться от момента загрузки страницы до окончания загрузки данных,
    // но у нас достаточно костыльное решение, чтобы проигнорировать это; будем считать, что это
    // метод компоненты, который скролит в любой момент к текущему якорю
    const { hash } = window.location;
    const section = this.$refs[hash.slice(1)] as (OlympSection | undefined);
    if (section) {
      section.scrollIntoView();
    }
  }

  sectionsLoadedOnce = new Set<string>();

  didInitialScroll = false;

  onSectionLoaded(name: string) {
    if (!this.didInitialScroll && this.sectionsLoadedOnce.size < this.allSections.length) {
      this.sectionsLoadedOnce.add(name);
      if (this.sectionsLoadedOnce.size === this.allSections.length) {
        this.scrollToAnchor();
        this.didInitialScroll = true;
      }
    }
  }
}
