















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OlympCheckbox extends Vue {
  @Prop() value?: boolean;

  @Prop() label?: string;
}
