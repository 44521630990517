
































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OlympCard extends Vue {
  @Prop() title!: string;

  @Prop() description!: string;

  @Prop() url!: string;

  @Prop({
    default: null,
  }) image!: { url: string } | null;

  @Prop() types!: {
    id: number;
    title: string;
    color: number | null;
  }[];

  @Prop() startDate?: string | null;

  @Prop() endDate?: string | null;

  @Prop() comment?: string;

  @Prop() callToActionLabel?: string;

  @Prop() formattedDates!: string;

  get formattedStartDate() {
    if (!this.startDate) return '';
    const date = new Date(this.startDate);
    return `${date.getDate()}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
  }

  get formattedEndDate() {
    if (!this.endDate) return '';
    const date = new Date(this.endDate);
    return `${date.getDate()}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
  }
}
