













import { Component, Prop, Vue } from 'vue-property-decorator';
import OlympCard from './OlympCard.vue';

@Component({
  components: { OlympCard },
})
export default class OlympList extends Vue {
  @Prop({
    default: () => [],
  }) items!: { id: number }[];
}
