





































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import { VueApolloComponentOptions, VueApolloQueryDefinition } from 'vue-apollo/types/options.d';
import OlympSelect from './OlympSelect.vue';
import OlympButton from './OlympButton.vue';
import OlympCheckbox from './OlympCheckbox.vue';
import OlympList from './OlympList.vue';
import OlympTag from './OlympTag.vue';

interface TitledThing {
  id: number;
  title: string;
}

interface SectionDescriptor {
  title?: string;
  name: string;
}

interface Option {
  value: number;
  label: string | number;
}

function ucfirst(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function createReferenceQuery<T extends Record<string, TitledThing[]>>(
  name: string,
  override?: Partial<VueApolloQueryDefinition<T>>,
): VueApolloQueryDefinition<T> {
  return {
    query: gql`{
      ${name}: olympCompetition${ucfirst(name)} {
        id
        title
      }
    }`,
    update(data) {
      return data[name].map(({ id, title }) => ({ value: id, label: title }));
    },
    ...override,
  };
}

function createReferenceOptions<T>(
  ...names: (string | [string, Partial<VueApolloQueryDefinition>])[]
) {
  return names.reduce((acc, val) => {
    const [name, override] = Array.isArray(val) ? val : [val];
    acc[name] = createReferenceQuery(name, override);
    return acc;
  }, {} as VueApolloComponentOptions<T>);
}

function queryNameBySection(section: string) {
  return [...section.split('_'), 'competitions']
    .map((v, i) => (i > 0 ? ucfirst(v) : v))
    .join('');
}

function createListQuery(
  section: string,
  override?: Partial<VueApolloQueryDefinition>,
): VueApolloQueryDefinition {
  const name = queryNameBySection(section);

  return {
    query: gql`
      query (
        $types: [Int!]
        $directions: [Int!]
        $preferences: [Int!]
        $isInProgress: Boolean
        $grades: [Int!]
        $section: OlympSectionName
      ) {
        ${name}: olympCompetitions(
          types: $types,
          directions: $directions,
          preferences: $preferences,
          isInProgress: $isInProgress,
          grades: $grades,
          section: $section
        ) {
          id
          title
          description
          image {
            url
          }
          types {
            title
            color
          }
          startDate
          endDate
          url
          comment
          callToActionLabel
          formattedDates
        }
      }
    `,
    variables(this: OlympSection) {
      return {
        types: this.selectedTypes,
        directions: this.selectedDirections,
        preferences: this.selectedPreferences,
        grades: this.selectedGrades,
        isInProgress: this.isInProgress,
        section,
      };
    },
    ...override,
  };
}

@Component({
  components: {
    OlympSelect, OlympButton, OlympCheckbox, OlympList, OlympTag,
  },
  apollo: createReferenceOptions(
    'types',
    ['preferences', {
      query: gql`
        query ($sections: [OlympSectionName!]) {
          preferences: olympCompetitionPreferences(sections: $sections) {
            id
            title
          }
        }
      `,
      variables(this: OlympSection) {
        return {
          sections: this.localSections.map(({ name }) => name),
        };
      },
    }],
  ),
})
export default class OlympSection extends Vue {
  @Prop() title?: string;

  @Prop({
    type: Boolean,
  }) noFilter?: boolean;

  @Prop({
    type: Boolean,
  }) forSchool?: boolean;

  @Prop({
    default: () => [],
  }) sections!: SectionDescriptor[];

  grades = [
    ...[5, 6, 7, 8, 9, 10, 11].map((it) => ({ value: it, label: `${it} класс` })),
    { value: -1, label: 'Студент СПО' },
  ];

  selectedTypes: number[] = [];

  selectedDirections: number[] = [];

  selectedPreferences: number[] = [];

  selectedGrades: number[] = [];

  isInProgress = false;

  isTagsExpanded = false;

  get localSections(): (SectionDescriptor & { queryName: string })[] {
    return this.sections.map((section) => ({
      ...section,
      queryName: queryNameBySection(section.name),
    }));
  }

  get hasSomeItems(): boolean {
    return this.localSections
      .map(({ queryName }) => this.$data[queryName])
      .some((items) => items && items.length);
  }

  getLabel = (tags: Option[], value: number) => tags.find((item) => item.value === value)?.label;

  createAndAddSmartQuery(
    name: string | [string, string],
    createFn: (
      name: string, override?: Partial<VueApolloQueryDefinition>
    ) => VueApolloQueryDefinition,
    fnArg?: Partial<VueApolloQueryDefinition>,
  ) {
    // REVIEW Дичь!
    const [paramName, queryName] = Array.isArray(name)
      ? name
      : [name, name];

    this.$apollo.addSmartQuery(queryName, createFn(paramName, fnArg));
  }

  created() {
    this.sections.forEach(
      ({ name }) => this.createAndAddSmartQuery(
        [name, queryNameBySection(name)],
        createListQuery,
        {
          result: () => {
            this.$emit('sectionLoaded', name);
          },
        },
      ),
    );

    this.createAndAddSmartQuery('directions', createReferenceQuery, {
      query: gql`
        query ($isForSchool: Boolean) {
          directions: olympCompetitionDirections(isForSchool: $isForSchool) {
            id
            title
          }
        }
      `,
      variables(this: OlympSection) {
        return {
          isForSchool: this.forSchool,
        };
      },
    });
  }

  scrollIntoView() {
    (this.$refs.container as Element).scrollIntoView();
  }
}
