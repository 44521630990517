































































import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class OlympSelect extends Vue {
  @Prop() value!: unknown[];

  @Prop() label?: string;

  @Prop({
    default: '-',
  }) nullLabel?: string;

  @Prop({
    default: () => [],
  }) options!: { value?: string; label?: string }[];

  isOpened = false;

  get fullLabel() {
    const total = this.value.length ? `(${this.value.length})` : '';
    return `${this.label}  ${total}`;
  }

  get model() {
    return this.value;
  }

  set model(v) {
    this.$emit('input', v);
  }
}
